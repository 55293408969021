import noop from "lodash/noop";
import Router, { useRouter } from "next/router";
import React, { useContext, useState } from "react";
import { DownloadAppBanner } from "../DownloadAppBanner";
interface PageLayoutContextProps {
  onBookDemo: (source?: string) => void;
  showDownloadBanner: (source?: string) => void;
}
const PageLayoutContext = React.createContext<PageLayoutContextProps>({
  onBookDemo: noop,
  showDownloadBanner: noop
});
export const PageLayoutProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const [downloadBannerVisible, setDownloadBannerVisible] = useState(false);
  const {
    asPath
  } = useRouter();
  const onBookDemo = () => {
    if (asPath.startsWith(`/life-insurance`)) {
      return void Router.push("/demo-carriers");
    }
    if (asPath.startsWith(`/lifevault`)) {
      return void Router.push("/demo-lifevault");
    }
    return void Router.push("/demo");
  };
  const showDownloadBanner = () => {
    setDownloadBannerVisible(true);
  };
  return <PageLayoutContext.Provider value={{
    showDownloadBanner,
    onBookDemo
  }} data-sentry-element="unknown" data-sentry-component="PageLayoutProvider" data-sentry-source-file="PageLayoutContext.tsx">
      {children}
      <DownloadAppBanner visible={downloadBannerVisible} onClose={() => setDownloadBannerVisible(false)} data-sentry-element="DownloadAppBanner" data-sentry-source-file="PageLayoutContext.tsx" />
    </PageLayoutContext.Provider>;
};
export const useDownloadBanner = () => {
  return useContext(PageLayoutContext);
};
export const useBookDemo = () => {
  return useContext(PageLayoutContext);
};
export const usePageLayoutActions = () => {
  return useContext(PageLayoutContext);
};