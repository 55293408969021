import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
interface Props {
  title: string;
  colorVariant?: "dark" | "light";
  bordered?: boolean;
  wide?: boolean;
  float?: boolean;
  variant?: "regular" | "bold";
  panelClassName?: string;
  sidebar?: React.ReactNode;
  children: React.ReactNode;
}
export const PopoverDropdown: React.FC<Props> = ({
  title,
  children,
  bordered,
  wide,
  float = true,
  variant = "regular",
  colorVariant = "dark",
  panelClassName,
  sidebar
}) => {
  const buttonRef = useRef(null);
  const [isHover, setIsHover] = useState(false);
  const ref = useRef(null);
  const isShowing = isHover;
  const debounced = useMemo(() => debounce((isHover: boolean) => {
    setIsHover(isHover);
  }, 100), []);
  return <Popover ref={ref} onFocus={() => debounced(true)} onBlur={() => debounced(false)} onMouseEnter={() => debounced(true)} onMouseLeave={() => {
    debounced(false);
  }} className={clsx("relative flex", {
    "w-full": wide
  })} data-sentry-element="Popover" data-sentry-component="PopoverDropdown" data-sentry-source-file="PopoverDropdown.tsx">
      {() => <div className="flex flex-1 flex-col">
          <Popover.Button ref={buttonRef} className="flex-1 outline-none focus:after:absolute focus:after:inset-0 focus:after:mb-1 focus:after:border-b-[1.5px] focus:after:border-plum-16 focus:after:content-['']">
            <div className={clsx("flex flex-1 items-center rounded text-sm font-normal", {
          "justify-between border px-4 py-3": bordered,
          "border-plum-64": bordered && colorVariant === "dark",
          "border-white": bordered && colorVariant === "light"
        }, {
          "px-1 pt-1 ": !bordered
        }, {
          "text-plum": colorVariant === "dark",
          "rounded-full text-white": colorVariant === "light"
        }, {
          "font-semibold": variant === "bold"
        })}>
              {title}
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2 outline-none">
                <path d="M11 1.5L6 6.5L1 1.5" stroke={colorVariant === "dark" ? "#1E1928" : "#FFFFFF"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </Popover.Button>
          <Transition show={isShowing} as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 -translate-y-2" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 -translate-y-2">
            <Popover.Panel className={clsx("top-8 mt-6 transform rounded-lg bg-white px-2 text-sm font-normal shadow-2xl sm:px-0 lg:ml-0", panelClassName, {
          "absolute left-0 z-10 ": float
        })}>
              <div className="relative flex flex-col items-stretch whitespace-nowrap px-5 py-6 sm:p-8">
                {children}
              </div>
              {sidebar}
            </Popover.Panel>
          </Transition>
        </div>}
    </Popover>;
};
export function useOnClickOutside<T extends HTMLDivElement>(ref: React.RefObject<T>, handler: (e: any) => void) {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}