import clsx from "clsx";
import Link from "next/link";
import React, { ReactNode } from "react";
import { CookiesConsent } from "./CookiesConsent";
import { Footer } from "./Layout/Footer";
import { Header } from "./Layout/Header";
import { PageLayoutProvider } from "./Layout/PageLayoutContext";
import { TestUserLayout } from "./TestUserLayout";
import { TopBanner } from "./Layout/TopBanner";
interface Props {
  preview?: boolean;
  className?: string;
  header?: ReactNode;
  headerProps?: React.ComponentProps<typeof Header>;
  footer?: ReactNode;
  banner?: ReactNode;
  minHeightScreen?: boolean;
  children: React.ReactNode;
}
export const PageLayout: React.FC<Props> = ({
  className,
  header,
  headerProps,
  footer,
  banner,
  children,
  preview = false,
  minHeightScreen = true,
  ...props
}) => {
  return <PageLayoutProvider data-sentry-element="PageLayoutProvider" data-sentry-component="PageLayout" data-sentry-source-file="PageLayout.tsx">
      <div {...props} className={clsx("relative flex w-full max-w-[100vw] flex-1 flex-col", className)}>
        {preview ? <div className="text-body-tiny cursor-pointer bg-plum-8 p-1 text-center uppercase">
            <Link prefetch={false} href="/api/exit-preview" className="z-100">
              Exit Preview
            </Link>
          </div> : null}
        <div>
          <TestUserLayout data-sentry-element="TestUserLayout" data-sentry-source-file="PageLayout.tsx" />
        </div>
        {banner || <TopBanner />}
        <div className={clsx("relative h-full", {
        "min-h-screen": minHeightScreen
      })}>
          {header || <Header {...headerProps} />}
          <main role="main">{children}</main>
        </div>
        {footer || <Footer />}
      </div>
      <CookiesConsent data-sentry-element="CookiesConsent" data-sentry-source-file="PageLayout.tsx" />
    </PageLayoutProvider>;
};
export { useBookDemo, useDownloadBanner, usePageLayoutActions } from "./Layout/PageLayoutContext";